import '../sass/hero.scss';

(() => {
    const $heros = document.querySelectorAll('.hero');

    if (!$heros.length) return;

    $heros.forEach($hero => {
        const $carousel = $hero.querySelector('.hero__carousel');

        const initCarousel = () => {
            if (!$carousel) return;

            const swiperScript = document.querySelector('#citrus-theme-swiper-js');

            if (!swiperScript) return;

            if (typeof Swiper !== 'undefined') { 
                new Swiper($carousel, {
                    loop: true,
                    effect: 'fade',
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false
                    },
                    fadeEffect: {
                        crossFade: true
                    },
                });
            }

            swiperScript.addEventListener('load', () => {
                new Swiper($carousel, {
                    loop: true,
                    effect: 'fade',
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false
                    },
                    fadeEffect: {
                        crossFade: true
                    },
                });
            });
        };

        initCarousel();
    });
})();
